import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

import { Button } from '../atoms'

class CopyWithInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired
  }

  state = {
    copyButtonLabel: 'Copy Link'
  }

  render () {
    const { value } = this.props
    const { copyButtonLabel } = this.state

    return (
      <div className="copy-with-input">
        <input value={value} readOnly className="input-wrapper__input" />
        <div className="copy-with-input__button">
          <Button onClick={this._copy.bind(this)} size="small">{copyButtonLabel}</Button>
        </div>
      </div>
    )
  }

  _copy () {
    copy(this.props.value)
    this.setState({ copyButtonLabel: 'Copied!' })
    setTimeout(() => {
      this.setState({ copyButtonLabel: 'Copy Link' })
    }, 700)
  }
}

export default CopyWithInput

import v8n from 'v8n'
import isemail from 'isemail'
import cpf from 'cpf'

v8n.extend({
  validEmail: () => {
    return value => isemail.validate(value)
  },
  validDocument: () => {
    return value => cpf.isValid(value)
  },
  validDate: () => {
    return value => v8n().pattern(/\d{4}-\d{2}-\d{2}/).test(value)
  },
  inList: (list) => {
    return value => list.includes(value)
  },
  validPhone: () => {
    return value => v8n().pattern(/\(\d{2}\) \d{4,5}-\d{4}/).test(value)
  },
  validStatus: () => {
    return value => v8n().not.null().inList(['enabled', 'disabled']).test(value)
  },
  validYoutubeId: () => {
    return value => {
      return !value || value.length === 11
    }
  },
  validHour: () => {
    return value => {
      const [hours, minutes] = value.split(':')
      return (
        +hours <= 23 &&
        +hours >= 0 &&
        +minutes <= 59 &&
        +minutes >= 0
      )
    }
  },
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon } from '../atoms'
import { closeNotification } from '../../store/actions/notification'

class Notification extends Component {
  static defaultProps = {
    delay: 7000
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.message !== this.props.message &&
      this.props.message
    ) {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this._onCloseNotification()
      }, this.props.delay)
    }
  }

  render() {
    const { message } = this.props
    const classes = this._getClasses()

    return (
      <div className={classes}>
        <div className="notification__text">
          {message}
        </div>
        <div
          className="notification__button"
          onClick={this._onCloseNotification}
        >
          <Icon name='close' />
        </div>
      </div>
    )
  }

  _onCloseNotification = () => {
    this.props.closeNotification()
  }

  _getClasses = () => {
    let classes = ['notification']
    if (this._getActive()) {
      classes.push('notification--active')
    }
    if (this._getError()) {
      classes.push('notification--error')
    }
    return classes.join(' ')
  }

  _getActive = () => {
    const { message } = this.props
    let active = false
    if (message) {
      active = true
    }
    return active
  };

  _getError = () => {
    const { messageType } = this.props
    let error = false
    if (messageType === 'error') {
      error = true
    }
    return error
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.notification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeNotification: () => dispatch(closeNotification())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)

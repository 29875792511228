import React from 'react'
import { connect } from 'react-redux'
import { loadList } from '../store/actions/generic'

export default function withLoadOptions(WrappedComponent, { item, modules }) {
  const mapStateToProps = ({ admin, generic }) => ({
    admin,
    generic
  })

  const mapDispatchToProps = {
    loadList
  }

  return connect(mapStateToProps, mapDispatchToProps)(
    class extends React.Component {
      state = {
        options: {}
      }

      componentDidMount () {
        this._loadOptions()
      }

      render() {
        const { options } = this.state
        return (
          <WrappedComponent
            {...this.props}
            item={item}
            modules={modules}
            options={options}
          />
        )
      }

      _loadOptions = async () => {
        const { token } = this.props.admin.admin
        const { loadList } = this.props
        const { fields } = item
        const output = {
          ...this.state.options
        }
        const promises = fields
          .filter((item) => item.loadOptions)
          .map(async (item) => {
            const moduleItem = modules[item.loadOptions]
            if (moduleItem) {
              const result = await loadList(moduleItem, token, {
                options: 'true'
              })
              output[moduleItem.name] = result.data.list.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                }
              })
            }
          })
        await Promise.all(promises)
        this.setState({ options: output })
      }
    }
  )
}

import uploads from './uploads'
import projectCategories from './project_categories'
import teamMembers from './team_members'
import partners from './partners'
import projects from './projects'
import jobs from './jobs'
import storeProducts from './store_products'
import storeCategories from './store_categories'
import storePages from './store_pages'
import ronin from './ronin'

export default {
  uploads,
  projectCategories,
  teamMembers,
  partners,
  projects,
  jobs,
  storeProducts,
  storeCategories,
  storePages,
  ronin,
}

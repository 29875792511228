const domUtils = {
  addBodyClass (name) {
    document.body.classList.add(name)
  },
  removeBodyClass (name) {
    document.body.classList.remove(name)
  },
}

export default domUtils

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import routes from './routes'
import { AppLayout } from './components/templates'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      rest.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/login',
            state: {
              lastLocation: props.location
            }
          }} />
    )} />
  )
}

class App extends Component {
  render() {
    const { admin } = this.props.admin
    const isAuthenticated = admin !== null
    return (
      <BrowserRouter>
        <AppLayout>
          <Switch>
            {routes.routes.map(route => {
              if (route.requiresAuth) {
                return (
                  <PrivateRoute
                    exact
                    isAuthenticated={isAuthenticated}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              } else {
                return (
                  <Route
                    exact
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              }
            })}
          </Switch>
        </AppLayout>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

export default connect(mapStateToProps, null)(
  withCookies(App)
)

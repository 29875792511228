import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner } from '../molecules'
import { SettingItem } from '../organisms'
import {
  loadSettings,
  updateSetting
} from '../../store/actions/setting'

class Settings extends Component {
  state = {
    filterSetting: null,
    loaded: false
  }

  componentDidMount () {
    this._loadData()
  }

  render () {
    const { sending, list } = this.props.setting
    const { loaded } = this.state
    return (
      <div>
        <h1 className="page-title page-title--bold">Configurações</h1>
        <p>Após alterar a configuração desejada, basta apertar <code className="code">Enter</code> ou dar desfoque no campo.</p>
        <br />
        {sending && list.length === 0 &&
          <div className="center">
            <Spinner />
          </div>
        }
        {loaded &&
          <div>
            {this._getGroupFilters()}
            <br/>
            <br/>
            {this._getItems()}
          </div>
        }
      </div>
    )
  }

  _getItems = () => {
    const { sending, list, currentID } = this.props.setting
    return list
      .filter(this._filterItems)
      .map(item => {
        const sendingItem = (
          sending &&
          item.id === currentID
        )
        return (
          <SettingItem
            key={item.id}
            id={item.id}
            description={item.description}
            name={item.name}
            value={item.value}
            public={item.public}
            onChange={this._updateSetting}
            sending={sendingItem}
          />
        )
      })
  }

  _loadData = async () => {
    const { token } = this.props.admin.admin
    await this.props.loadSettings(token)
    const groups = this._getGroups()
    const filterSetting = groups.shift()
    this.setState({ filterSetting, loaded: true })
  }

  _updateSetting = data => {
    const { token } = this.props.admin.admin
    this.props.updateSetting(token, data)
  }

  _filterItems = item => {
    if (this.state.filterSetting) {
      return item.description.startsWith(this.state.filterSetting)
    }
    return true
  }

  _getGroups = () => {
    const { list } = this.props.setting
    return list
      .map(item => item.description)
      .map(value => value.split(' - ').shift().trim())
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  _getGroupFilters = () => {
    const groups = this._getGroups()
    return groups.map(item => {
      const enabled = item === this.state.filterSetting
      const classes = ['button', 'button--small']
      if (enabled) {
        classes.push('button--disabled')
      }
      const onClick = () => {
        this.setState({ filterSetting: item })
      }
      return (
        <span
          key={item}
          className={classes.join(' ')}
          onClick={onClick}
        >
          {item}
        </span>
      )
    })
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    setting: state.setting
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadSettings: token => dispatch(loadSettings(token)),
    updateSetting: (token, data) => dispatch(updateSetting(token, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)


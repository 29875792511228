import React, { Component } from 'react'
import agencyLogoSource from '../../assets/svg/agency-logo.svg'

export default class AgencyLogo extends Component {
  render () {
    return (
      <div className="agency-logo">
        <div className="agency-logo__image">
          <img src={agencyLogoSource} alt='Logo' />
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import { TextInputMask } from 'tp-react-web-masked-text'

class Input extends Component {
  static propTypes = {
    boxSize: PropTypes.string,
    intent: PropTypes.string
  }

  render() {
    const className = this._getClasses()
    return (
      <div className={className}>
        {this._getInput()}
      </div>
    )
  }

  focus() {
    if (this.inputRef) {
      if (this.inputRef.inputElement) {
        this.inputRef.inputElement.focus()
      } else {
        this.inputRef.focus()
      }
    }
  }

  _getInput = () => {
    const safeProps = this._safeProps()
    const maskData = this._getMask();
    if (maskData) {
      if (maskData === 'money') {
        return (
          <TextInputMask
            {...safeProps}
            className='input-wrapper__input'
            kind='money'
            onChange={(_, value) => {
              this.props.onChange({
                target: {
                  value
                }
              })
            }}
          />
        )
      } else {
        return (
          <MaskedInput
            {...safeProps}
            ref={ref => this.inputRef = ref}
            mask={maskData}
            className='input-wrapper__input'
          />
        )
      }
    } else {
      return (
        <input
          {...safeProps}
          ref={ref => this.inputRef = ref}
          className='input-wrapper__input'
        />
      )
    }
  }

  _getClasses = () => {
    const {
      boxSize,
      intent,
      className
    } = this.props
    let classes = ['input-wrapper']
    if (boxSize) classes.push(`input-wrapper--${boxSize}`)
    if (intent) classes.push(`input-wrapper--${intent}`)
    if (className) classes.push(className)
    return classes.join(' ')
  }

  _safeProps = () => {
    let safeProps = { ...this.props }
    delete safeProps.boxSize
    delete safeProps.intent
    delete safeProps.mask
    delete safeProps.label
    return safeProps
  }

  _getMask = () => {
    const { mask } = this.props
    const masks = {
      cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      hourRange: [/\d/, /\d/, ':', /\d/, /\d/, '-', /\d/, /\d/, ':', /\d/, /\d/],
      money: 'money',
      date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      hour: [/\d/, /\d/, ':', /\d/, /\d/],
    }
    return masks[mask]
  }
}

export default Input

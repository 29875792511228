import React, { Component } from 'react'
import { connect } from 'react-redux'
import withLoadOptions from '../../hocs/with_load_options'
import { loadItem, updateItem } from '../../store/actions/generic'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { GenericForm } from '../organisms'

class EditGeneric extends Component {
  constructor(props) {
    super(props)
    const { item } = props
    const { token } = props.admin.admin
    const { id } = props.match.params
    props.loadItem(item, token, id)
  }

  render() {
    const { item } = this.props
    const { data, sending } = this.props.generic.modules[item.name]
    return (
      <div>
        <BackToList to={`${item.name}List`} />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar {item.singular}</h1>
            {data &&
              <GenericForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
                validator={item.validator}
                fields={item.fields}
                beforeSubmit={item.beforeSubmit}
                formatData={item.formatData}
                options={this.props.options}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name={item.name} />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { item } = this.props
    const { token } = this.props.admin.admin
    this.props.updateItem(item, token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, generic }) => ({
  admin,
  generic
})

const mapDispatchToProps = {
  loadItem,
  updateItem,
  notificate
}

export default function ({ item, modules }) {
  return withLoadOptions(
    connect(mapStateToProps, mapDispatchToProps)(EditGeneric),
    { item, modules }
  )
}

import axios from 'axios'

const vimeo = {
  getIdByLink(link) {
    return link.split('/').pop()
  },
  async getImage(id, size = 'medium') {
    try {
      const url = `https://vimeo.com/api/v2/video/${id}.json`
      const { data } = await axios.get(url)
      if (data.length > 0) {
        return data[0][`thumbnail_${size}`]
      }
    } catch (error) {
      console.log('vimeo thumb error:', error)
    }
    return null
  }
}

export default vimeo

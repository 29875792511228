import routes from '../routes'

export default {
  /**
   * Redirecionamento
   * @param {object} options
   * @param {object} options.screen Para onde irá
   * @param {object} options.params Parametros da tela se necessario
   * @param {object} options.props Props do component
   * @returns {boolean} Retorna false para evitar erro com render
   */
  to ({ screen, params, props }) {
    const route = routes.routes.find(route => route.name === screen)
    if (route) {
      const path = this.applyWebParams(route.path, params)
      props.history.push(path)
    }
    return false
  },

  /**
   * Aplica parametros para web
   * @param {string} path
   * @param {object} params
   * @returns {string}
   */
  applyWebParams (path, params) {
    let newPath = path
    if (params && typeof params === 'object') {
      Object.keys(params).forEach(field => {
        const value = params[field]
        newPath = newPath.replace(`:${field}`, value)
      })
    }
    return newPath
  }
}

import modules from '../../modules'

const initialState = {
  modules: {}
}

for (const name of Object.keys(modules)) {
  initialState.modules[name] = {
    list: [],
    data: null,
    paging: null,
    sending: false,
    filters: {}
  }
}

const identifier = 'generic'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.modules[action.name].sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.modules[action.name].sending = false
      }
      break

    case 'SET_GENERIC_LIST':
      const filters = nextState.modules[action.name].filters
      let list = [...action.data.list]
      if (
        action.data.paging.page > 1 &&
        filters.infinite_scroll
      ) {
        list = nextState.modules[action.name].list.concat(
          action.data.list
        )
      }
      nextState.modules[action.name] = {
        ...nextState.modules[action.name],
        list,
        paging: action.data.paging
      }
      break

    case 'SET_GENERIC_ITEM':
      nextState.modules[action.name].data = action.data
      break

    case 'SET_GENERIC_LIST_FILTERS':
      nextState.modules[action.name].filters = action.data
      break

    case 'SORT_GENERIC_LIST':
      nextState.modules[action.name].list = nextState.modules[action.name].list
        .map(item => {
          const sortItem = action.data.find(v => +v.id === +item.id)
          if (sortItem) {
            item.sort = sortItem.sort
          }
          return item
        })
        .sort((a, b) => {
          if (a.sort > b.sort) return 1
          if (a.sort < b.sort) return -1
          return 0
        })
      break

    case 'REMOVE_GENERIC_LIST_ITEM':
      nextState.modules[action.name].list = nextState.modules[action.name]
        .list
        .filter((item) => {
          return +item.id !== +action.data
        })
      break

    default:
      nextState = state
      break
  }

  return nextState
}

export const notificate = (message, messageType = 'error') => {
  return {
    type: 'SET_NOTIFICATION',
    message,
    messageType
  }
}

export const closeNotification = () => {
  return notificate(null)
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../atoms'
import { FormControl, DatePicker } from '../molecules'

class ListFilters extends Component {
  static propTypes = {
    data: PropTypes.object,
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
    filterOptions: PropTypes.object,
    onChange: PropTypes.func.isRequired
  }

  state = {
    model: {
      filters: []
    }
  }

  componentDidMount() {
    this.delayInterval = 0
    const { data } = this.props
    const model = {
      ...this.state.model,
      filters: this.props.filters.map(filter => {
        let value = ''
        if (data && data[filter]) {
          value = data[filter]
        }
        return { filter, value }
      })
    }
    this.setState({ model })
  }

  render() {
    return (
      <div className="list-filters">
        {this._getFilters()}
        <Button
          className="list-filters__clear-filters"
          size="small"
          onClick={this._clearFilters}
          disabled={!this._hasFilters()}
        >Limpar filtros</Button>
      </div>
    )
  }

  _getFilters = () => {
    const { model } = this.state
    const { filterOptions } = this.props
    return this.props.filters.map((filter, index) => {
      const filterValue = model.filters.find(item => {
        return item.filter === filter
      })
      switch (filter) {
        case 'search':
          return (
            <FormControl
              key={index}
              label='Filtrar por palavra-chave'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        case 'date':
          return (
            <DatePicker
              key={index}
              label='Selecione uma data'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        case 'status':
          const { options } = filterOptions.status
          return (
            <FormControl
              key={index}
              options={options}
              type='select'
              label='Status'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        case 'category_id': {
          const { options } = filterOptions.category_id
          return (
            <FormControl
              key={index}
              options={options}
              type='select'
              label='Categoria'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )
        }

        case 'tag_id': {
          const { options } = filterOptions.tag_id
          return (
            <FormControl
              key={index}
              options={options}
              type='select'
              label='Tag'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )
        }

        case 'highlighted': {
          const { options, label } = filterOptions.highlighted
          return (
            <FormControl
              key={index}
              options={options}
              type='select'
              label={label || 'Destaque home'}
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )
        }

        default:
          return 'Invalid filter'
      }
    })
  }

  _onChange = (filter, value) => {
    const model = {
      ...this.state.model,
      filters: this.state.model.filters.map(item => {
        if (item.filter === filter) {
          item.value = value
        }
        return item
      })
    }
    this.delayInterval = filter === 'search'
      ? 500
      : 0
    this.setState({ model }, this._sendChanges)
  }

  _sendChanges = () => {
    clearInterval(this.interval)
    this.interval = setTimeout(() => {
      this.props.onChange(this.state.model.filters)
    }, this.delayInterval)
  }

  _getLocales = () => {
    const firstOption = {
      value: '',
      label: 'Todos'
    }
    return [firstOption].concat(
      this.props.locale.list.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })
    )
  }

  _hasFilters = () => {
    return this.state.model.filters
      .some(item => item.value)
  }

  _clearFilters = () => {
    const model = {
      ...this.state.model,
      filters: this.state.model.filters.map(item => {
        item.value = ''
        return item
      })
    }
    this.delayInterval = 0
    this.setState({ model }, this._sendChanges)
  }
}

export default ListFilters

import slugify from 'slugify'

export class Job {
  constructor (job = {}) {
    Object.assign(this, job)
  }

  getLink () {
    const jobSlug = `${slugify(this.title)}-${this.id}`.toLowerCase()
    const link = `https://dojo.do/pessoas#carreiras?job=${jobSlug}`

    return link
  }
}

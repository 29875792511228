import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'storePages'

const storePageValidator = {
  create(data) {
    // {
    //   name: v8n().not.null().minLength(3).maxLength(255),
    // }
    const schema = {}
    return v8n()
      .schema(schema)
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },
  edit(data) {
    return this.create(data)
  },
}

export default storePageValidator

import { projectCategoryValidator as validator } from '../validators'

const fields = [
  {
    name: 'Nome',
    field: 'name',
    type: 'text'
  },
  {
    name: 'Slug',
    field: 'slug',
    type: 'slug',
    props({ model }) {
      return {
        mirror: model.name
      }
    }
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  return {
    ...data
  }
}

export default {
  singular: 'Categoria',
  plural: 'Categorias',
  endpoint: 'project-categories',
  name: 'projectCategories',
  slug: 'categorias',
  sortable: false,
  sortableField: 'name',
  filters,
  fields,
  validator,
  beforeSubmit
}

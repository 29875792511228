import React, { Component } from 'react'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import classNames from 'classnames'
import { Button, Icon } from '../atoms'
import { FormControl } from '../molecules'
import MediaLibrary from './MediaLibrary'
import vimeoUtils from '../../utils/vimeo_utils'

const DragHandle = sortableHandle(() => (
  <span className="project-relation-manager__item__drag-handle">
    <Icon name="drag-handle" />
  </span>
))

const SortableItem = sortableElement(({ children }) => (
  <li>
    {children}
  </li>
))

const SortableContainer = sortableContainer(({ children }) => {
  const classes = ['sortable-container']
  return <ul className={classes.join(' ')}>{children}</ul>;
})

export default class ProjectMediaManager extends Component {
  state = {
    model: {
      rows: [],
    },
    small: false,
  }

  componentDidUpdate () {
    if (
      Array.isArray(this.props.value) &&
      this.state.model.rows.length === 0
    ) {
      const rows = this.props.value
        .reduce((acc, item) => {
          acc[item.grid_row] = {
            grid_row_type: item.grid_row_type,
            columns: []
          }
          return acc
        }, [])
        .map((item, index) => {
          const columns = this.props.value
            .filter((item) => +item.grid_row === +index)
            .map((item, columnIndex) => {
              const preview = item.thumb_url || item.preview
              if (item.media_type === 'video') {
                setTimeout(() => {
                  this._checkVideoThumb(item.video_id, index, columnIndex)
                }, 500)
              }
              return {
                id: item.id,
                upload_id: item.upload_id,
                video_id: item.video_id ? item.video_id : '',
                media_type: item.media_type,
                grid_row_type: item.grid_row_type,
                sort: item.sort,
                preview,
              }
            })
          return {
            grid_row_type: item.grid_row_type,
            columns
          }
        })
      const model = {
        ...this.state.model,
        rows
      }
      this.setState({ model })
    }
  }

  render() {
    const { label } = this.props
    const { small } = this.state
    const classes = this._getClasses()
    const rows = this._renderRows()
    return (
      <div className={classes}>
        <label className="project-relation-manager__title">{label}</label>
        <FormControl
          type="switch"
          checked={small}
          label="Diminuir linhas para ordenar"
          onChange={(small) => this.setState({ small })}
        />
        <div className="project-relation-manager__list">
          <SortableContainer
            onSortEnd={this._onSortEnd}
            useDragHandle
            lockAxis="y"
          >
            {rows}
          </SortableContainer>
        </div>
        <div className="project-relation-manager__actions">
          <Button
            size="small"
            onClick={this._addRow}
          >Adicionar linha</Button>
        </div>
        {this._getErrors()}
      </div>
    )
  }

  _renderRows = () => {
    const mediaTypes = [
      { value: 'image', label: 'Imagem' },
      { value: 'video', label: 'Video' },
    ]
    const { rows } = this.state.model
    return rows.map((row, index) => {
      const smallItems = []
      const columns = row.columns.map((column, columnIndex) => {
        let preview = null
        if (column.preview) {
          preview = (
            <img
              key={`small-preview-${columnIndex}`}
              src={column.preview}
              className="thumbnail project-media-row__column__data__control__image"
              alt="Video"
            />
          )
        }

        if (preview) {
          smallItems.push(preview)
        }

        return (
          <div
            key={columnIndex}
            className="project-media-row__column"
          >
            <div className="project-media-row__column__options">
              <FormControl
                label="Tipo de mídia"
                type="radio"
                value={column.media_type}
                options={mediaTypes}
                onChange={value => this._onChangeColumn('media_type', value, index, columnIndex)}
              />
            </div>
            <div className="project-media-row__column__data">
              {column.media_type === 'image' && (
                <div className="project-media-row__column__data__control">
                  <div className="project-media-row__column__data__control__comp">
                    <MediaLibrary
                      preview={column.preview}
                      value={column.upload_id}
                      label="Imagem"
                      onChange={(value, upload) => {
                        this._onChangeColumn('upload_id', value, index, columnIndex)
                        this._onChangeColumn('preview', upload.thumb_url, index, columnIndex)
                      }}
                      errors={[]}
                    />
                  </div>
                </div>
              )}
              {column.media_type === 'video' && (
                <div className="project-media-row__column__data__control">
                  <div className="project-media-row__column__data__control__comp">
                    <FormControl
                      label="Vimeo ID"
                      type="text"
                      value={column.video_id}
                      onChange={value => this._onChangeColumn('video_id', value, index, columnIndex)}
                    />
                  </div>
                  {preview}
                </div>
              )}
            </div>
          </div>
        )
      })
      const rowClasses = classNames({
        'project-media-row': true,
        'project-media-row--small': this.state.small,
        [`project-media-row--variation-${row.grid_row_type}`]: true,
      })
      const options = this._getOptions(index, row.grid_row_type)
      return (
        <SortableItem
          key={`item-${index}`}
          index={index}
        >
          <div
            className="project-relation-manager__item"
          >
            <div className="project-relation-manager__item__control">
              <div className={rowClasses}>
                <label className="project-media-row__label">Layout da linha</label>
                <div className="project-media-row__options">
                  {options}
                </div>
                <div className="project-media-row__columns">
                  {columns}
                </div>
                <div className="project-media-row__small-items">
                  {smallItems}
                </div>
              </div>
            </div>
            <div className="project-relation-manager__item__control project-relation-manager__item__control--actions">
              <DragHandle />
              <span
                className="project-relation-manager__item__remove"
                onClick={() => this._removeItem(index)}
              >
                <Icon name="trash" />
              </span>
            </div>
          </div>
        </SortableItem>
      )
    })
  }

  _addRow = () => {
    const model = {
      ...this.state.model,
      rows: [
        ...this.state.model.rows,
        {
          grid_row_type: 0,
          columns: [
            {
              upload_id: '',
              video_id: '',
              media_type: 'image',
              grid_row_type: 0,
              sort: 0,
              preview: '',
            }
          ]
        },
      ]
    }
    this.setState({ model }, this._sendChanges)
  }

  _removeItem = (index) => {
    const model = {
      ...this.state.model,
      rows: this.state.model.rows.filter((_, i) => {
        return +i !== +index
      })
    }
    this.setState({ model }, this._sendChanges)
  }

  _getClasses = () => {
    let classes = ['project-relation-manager']
    if (this.props.errors.length > 0) {
      classes.push('project-relation-manager--error')
    }
    return classes.join(' ')
  }

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    const { rows } = this.state.model
    const newRows = arrayMove(rows, oldIndex, newIndex)
      .map((item, index) => ({
        ...item,
        sort: index
      }))
    const model = {
      ...this.state.model,
      rows: newRows
    }
    this.setState({ model }, this._sendChanges)
  }

  _sendChanges = () => {
    const items = this.state.model.rows.map((row, index) => {
      return row.columns.map((column) => {
        const item = {
          id: column.id,
          upload_id: column.upload_id,
          video_id: column.video_id,
          media_type: column.media_type,
          grid_row: +index,
          grid_row_type: row.grid_row_type,
          sort: column.sort,
        }
        if (item.media_type === 'image') {
          delete item.video_id
        } else {
          delete item.upload_id
        }
        return item
      })
    }).flat()
    this.props.onChange(items)
  }

  _getOptions = (rowIndex, value) => {
    const values = [
      {
        value: 0,
        columns: 1,
      },
      {
        value: 1,
        columns: 3,
      },
      {
        value: 2,
        columns: 3,
      },
      {
        value: 3,
        columns: 2,
      },
      {
        value: 4,
        columns: 3,
      },
      {
        value: 5,
        columns: 2,
      },
      {
        value: 6,
        columns: 2,
      },
    ]
    return values.map((item) => {
      const classes = classNames({
        'project-media-row__options__item': true,
        [`project-media-row__options__item--variation-${item.value}`]: true,
        'project-media-row__options__item--selected': item.value === value,
      })
      return (
        <div
          key={item.value}
          className={classes}
          onClick={() => this._changeRowType(item, rowIndex)}
        >
          <div className="project-media-row__options__item__icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )
    })
  }

  _changeRowType = (item, rowIndex) => {
    const rows = this.state.model.rows.map((row, index) => {
      if (+index === +rowIndex) {
        row.grid_row_type = item.value
        if (row.columns.length > item.columns) {
          row.columns = row.columns.slice(0, item.columns)
        } else if (row.columns.length < item.columns) {
          row.columns = row.columns.concat(
            Array(item.columns - row.columns.length)
              .fill({
                upload_id: '',
                video_id: '',
                media_type: 'image',
                preview: '',
              })
          )
        }
        row.columns = row.columns.map((column, index) => ({
          ...column,
          grid_row_type: item.value,
          sort: +index
        }))
      }
      return row
    })
    const model = {
      ...this.state.model,
      rows
    }
    this.setState({ model }, this._sendChanges)
  }

  _onChangeColumn = (field, value, rowIndex, columnIndex) => {
    clearTimeout(this.intervalVideoThumb)
    const model = {
      ...this.state.model,
      rows: this.state.model.rows.map((row, _rowIndex) => {
        if (+_rowIndex === +rowIndex) {
          row.columns = row.columns.map((column, _columnIndex) => {
            if (+_columnIndex === +columnIndex) {
              if (field === 'video_id') {
                column.preview = null
                const videoId = vimeoUtils.getIdByLink(value)
                if (videoId) {
                  value = videoId
                  setTimeout(() => {
                    this._checkVideoThumb(value, rowIndex, columnIndex)
                  }, 700)
                }
              }
              column[field] = value
            }
            return column
          })
        }
        return row
      })
    }

    this.setState((state) => ({
      ...state,
      model
    }), this._sendChanges)
  }

  _checkVideoThumb = async (videoId, rowIndex, columnIndex) => {
    if (videoId) {
      const result = await vimeoUtils.getImage(videoId)
      this._onChangeColumn('preview', result, rowIndex, columnIndex)
      return result
    }
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../molecules/Spinner'

class Button extends Component {
  static propTypes = {
    type: PropTypes.string,
    spinner: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    size: PropTypes.string,
    intent: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    type: 'button',
    onClick: () => {}
  };

  render () {
    const {
      type,
      spinner,
      disabled,
      block,
      size,
      intent,
      className,
      children,
      onClick,
      ...others
    } = this.props
    return (
      <button
        className={this._getClasses()}
        disabled={disabled}
        type={type}
        onClick={onClick}
        {...others}
      >
        <span className="button__text">{children}</span>
        <Spinner />
      </button>
    )
  };

  _getClasses = () => {
    const {
      spinner,
      block,
      size,
      intent,
      className
    } = this.props
    let classes = ['button']
    if (spinner) classes.push('button--spinner')
    if (block) classes.push('button--block')
    if (size) classes.push(`button--${size}`)
    if (intent) classes.push(`button--${intent}`)
    if (className) classes.push(className)
    return classes.join(' ')
  };
}

export default Button

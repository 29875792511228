import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from './FormControl'
import { date } from '../../utils'

class DatePickerComp extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  render () {
    const { label, value } = this.props
    return (
      <div className="date-picker">
        <FormControl
          label={label}
          type="calendar"
          value={this._formatDate(value)}
          onChange={this._onChange}
        />
      </div>
    )
  }

  _onChange = value => {
    this.props.onChange(date.formatDate(value, null, 'YYYY-MM-DD'))
  }

  _formatDate = value => {
    if (!value) return null
    return date.parseDate(value, 'YYYY-MM-DD')
  }
}

export default DatePickerComp

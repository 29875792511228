import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { links } from '../../utils'

export default class ModuleCount extends Component {
  static propTypes = {
    item: PropTypes.object
  }

  render () {
    const { item } = this.props
    return (
      <Link to={links.get(item.screen)} className="module-count">
        <div className="module-count__count">{item.count}</div>
        <div className="module-count__title">{item.label}</div>
        <div className="module-count__icon">+</div>
      </Link>
    )
  }
}

import { storePageValidator as validator } from '../validators'

const fields = [
  {
    name: 'Página',
    field: 'slug',
    type: 'text',
    hidden: true,
  },
  {
    name: 'Elementos da página',
    field: 'data',
    type: 'text',
    component: 'StorePageBuilder',
    props ({ model }) {
      return {
        slug: model.slug
      }
    }
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  return {
    ...data
  }
}

const translateSlug = (slug) => {
  const values = {
    home: 'Página inicial',
    about: 'Sobre a Coleção',
  }
  return values[slug] || slug
}

const formatList = (rows) => {
  return rows.map((data) => ({
    ...data,
    slug: translateSlug(data.slug)
  }))
}

export default {
  singular: 'Página de Produto',
  plural: 'Páginas de Produto',
  endpoint: 'store-pages',
  name: 'storePages',
  slug: 'paginas-de-produtos',
  sortable: false,
  sortableField: 'slug',
  listColumns: [
    'slug',
  ],
  disableAdd: true,
  disableFilters: true,
  disableDelete: true,
  filters,
  fields,
  validator,
  beforeSubmit,
  formatList
}

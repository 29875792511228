import React, { Component } from 'react'
import { connect } from 'react-redux'
import withLoadOptions from '../../hocs/with_load_options'
import { createItem } from '../../store/actions/generic'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { GenericForm } from '../organisms'

class CreateGeneric extends Component {
  render() {
    const { item } = this.props
    const { sending } = this.props.generic.modules[item.name]
    return (
      <div>
        <BackToList to={`${item.name}List`} />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de {item.singular}</h1>
            <GenericForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
              validator={item.validator}
              fields={item.fields}
              beforeSubmit={item.beforeSubmit}
              options={this.props.options}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name={item.name} />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { item } = this.props
    const { token } = this.props.admin.admin
    this.props.createItem(item, token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, generic }) => ({
  admin,
  generic
})

const mapDispatchToProps = {
  createItem,
  notificate,
}

export default function ({ item, modules }) {
  return withLoadOptions(
    connect(mapStateToProps, mapDispatchToProps)(CreateGeneric),
    { item, modules }
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../atoms'

class ImagePreview extends Component {
  static propTypes = {
    thumbnail: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    width: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string
  }

  state = {
    opened: false
  }

  render () {
    const { thumbnail, alt, width, className, image } = this.props
    const { opened } = this.state
    let imageClasses = ['image-preview__image']
    if (className) {
      imageClasses.push(className)
    }
    let classes = ['image-preview']
    if (opened) {
      classes.push('image-preview--opened')
    }
    return (
      <span
        className={classes.join(' ')}
        onClick={this._toggleModal}
      >
        <img
          className={imageClasses.join(' ')}
          src={thumbnail}
          alt={alt}
          width={width}
        />
        <div
          className="image-preview__full"
        >
          <img
            className="image-preview__full__image"
            src={image}
            alt={alt}
          />
          <span
            onClick={this._downloadImage}
            className="image-preview__full__download"
          ><span>Baixar</span> <Icon name='download' /></span>
        </div>
      </span>
    )
  }

  _toggleModal = () => {
    this.setState({ opened: !this.state.opened }, () => {
      if (this.state.opened) {
        this._addEventListener()
      } else {
        this._removeEventListener()
      }
    })
  }

  _downloadImage = () => {
    const { image } = this.props
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = image.split('/').pop()
    link.href = image
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  _addEventListener = () => {
    window.addEventListener('keyup', this._onKeyUp)
  }

  _removeEventListener = () => {
    window.removeEventListener('keyup', this._onKeyUp)
  }

  _onKeyUp = e => {
    if (e.key === 'Escape') {
      this._toggleModal()
    }
  }
}

export default ImagePreview

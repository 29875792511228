import React, { Component } from 'react'

export default class Header extends Component {
  render () {
    return (
      <footer className="main-footer">
        footer
      </footer>
    )
  }
}

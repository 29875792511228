import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Confirmation extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    header: PropTypes.string,
    body: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string
  }

  static defaultProps = {
    opened: false,
    header: 'Você tem certeza?',
    body: 'Essa ação não tem reversão, confirme se tiver certeza.',
    confirmLabel: 'Sim',
    cancelLabel: 'Não'
  }

  componentDidMount () {
    window.addEventListener('keydown', this._handleKeydown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this._handleKeydown)
  }

  render () {
    const classes = this._getClasses()
    const { header, body, confirmLabel, cancelLabel } = this.props
    return (
      <div className={classes}>
        <div className="confirmation__wrapper">
          <header className="confirmation__header">
            {header}
          </header>
          <div className="confirmation__content">
            {body}
          </div>
          <div className="confirmation__actions">
            <span className="confirmation__actions__button confirmation__actions__button--confirm" onClick={this.props.onConfirm}>{confirmLabel}</span>
            <span className="confirmation__actions__button confirmation__actions__button--cancel" onClick={this.props.onCancel}>{cancelLabel}</span>
          </div>
        </div>
      </div>
    )
  }

  _getClasses = () => {
    const { opened } = this.props
    let classes = ['confirmation']
    if (opened) {
      classes.push('confirmation--opened')
    }
    return classes.join(' ')
  }

  _handleKeydown = event => {
    const { opened } = this.props
    if (opened) {
      // eslint-disable-next-line
      switch (event.key) {
        case 'Enter':
          this.props.onConfirm()
          break

        case 'Escape':
          this.props.onCancel()
          break
      }
    }
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { links } from '../../utils'

export default class BackToList extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired
  }

  render () {
    const { to } = this.props
    return (
      <Link
        className='back-to-list'
        to={links.get(to)}
      >&larr; Voltar para listagem</Link>
    )
  }
}

import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse
} from './'

const identifier = 'dashboard'

const _setDashboard = data => ({ type: 'SET_DASHBOARD', data })

export const loadDahsboard = token => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/dashboard', {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setDashboard(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

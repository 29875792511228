import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'storeProducts'

const storeProductValidator = {
  create(data) {
    const schema = {
      store_category_id: v8n().not.null().number().greaterThan(0),
      image_upload_id: v8n().not.null().number().positive(),
      name: v8n().not.null().minLength(3).maxLength(255),
      description: v8n().not.null().minLength(3),
      price: v8n().number().positive(),
      store_product_variations: v8n().not.null().array().minLength(1),
      store_product_images: v8n().not.null().array().minLength(1),
    }
    return v8n()
      .schema(schema)
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },

  edit(data) {
    return storeProductValidator.create(data)
  }
}

export default storeProductValidator

import React from 'react'
import ImagePreview from '../components/molecules/ImagePreview'
import { roninValidator as validator } from '../validators'

const fields = [
  {
    name: 'Imagem da home',
    field: 'home_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.home_upload) {
        const thumbnail = row.home_upload.thumb_url
        const image = row.home_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.home_upload ? model.home_upload.url: null,
      }
    }
  },
  // {
  //   name: 'Imagem principal',
  //   field: 'header_upload_id',
  //   type: 'text',
  //   component: 'MediaLibrary',
  //   filter: (_, row) => {
  //     if (row.header_upload) {
  //       const thumbnail = row.header_upload.thumb_url
  //       const image = row.header_upload.url
  //       return (
  //         <ImagePreview
  //           thumbnail={thumbnail}
  //           image={image}
  //           alt={row.name}
  //           width="70"
  //           className="thumbnail"
  //         />
  //       )
  //     }
  //   },
  //   props({ model }) {
  //     return {
  //       preview: model.header_upload ? model.header_upload.url: null,
  //     }
  //   }
  // },
  {
    name: 'Categoria',
    field: 'project_category_id',
    type: 'select',
    loadOptions: 'projectCategories',
    filter: (_, row) => {
      return row.project_category
        ? row.project_category.name
        : null
    }
  },
  {
    name: 'Cliente',
    field: 'client',
    type: 'text'
  },
  {
    name: 'Título do projeto',
    field: 'title',
    type: 'text',
    props () {
      return {
        helpText: 'Para quebrar linha na lista de projetos, utilize o caracter "|". Exemplo: Linha 1 | Linha 2'
      }
    }
  },
  {
    name: 'Slug',
    field: 'slug',
    type: 'slug',
    props ({ model }) {
      return {
        mirror: model.title,
      }
    }
  },
  {
    name: 'Descrição',
    field: 'description',
    type: 'texteditor',
    props () {
      return {
        helpText: 'Para separar em colunas o texto, utilize o seguinte texto: "---" (3 traços, sem as aspas)'
      }
    }
  },
  {
    name: 'Ordem',
    field: 'sort',
    type: 'text',
  },
  // {
  //   name: 'Textos (Insight, Idea, Impact, etc)',
  //   field: 'project_texts',
  //   type: 'text',
  //   component: 'ProjectTextManager',
  // },
  // {
  //   name: 'Mídia',
  //   field: 'project_media',
  //   type: 'text',
  //   component: 'ProjectMediaManager'
  // },
  {
    name: 'Award',
    field: 'won_award',
    type: 'switch',
    filter: value => +value === 1 ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.won_award === undefined
          ? false
          : (+model.won_award === 1)
      }
    }
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  const newData = {
    ...data,
    project_type: 2,
    mobile_upload_id: data.home_upload_id,
    desktop_upload_id: data.home_upload_id,
    header_upload_id: data.home_upload_id,
    list_type: 0,
    project_category_id: +data.project_category_id,
    sort: +data.sort,
    status: data.status || data.status === undefined
      ? 'enabled'
      : 'disabled',
    project_links: [],
    project_media: [
      {
        upload_id: data.home_upload_id,
        grid_row: 0,
        grid_row_type: 0,
        sort: 1
      },
    ]
  }
  delete newData.project_category
  delete newData.home_upload
  delete newData.home_second_upload
  delete newData.mobile_upload
  delete newData.desktop_upload
  delete newData.header_upload
  return newData
}

const formatData = (data) => {
  return {
    ...data,
    status: data.status === 'enabled',
  }
}

const listExtraParams = {
  project_type: 2,
}

const showExtraParams = listExtraParams

export default {
  singular: 'Peça Ronin',
  plural: 'Peças Ronin',
  endpoint: 'projects',
  name: 'ronin',
  slug: 'ronin',
  sortable: true,
  sortableField: (row) => {
    return `${row.client} / ${row.title}`
  },
  listColumns: [
    'home_upload_id',
    'project_category_id',
    'client',
    'title',
    'won_award',
    'sort',
    'status',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData,
  listExtraParams,
  showExtraParams,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../atoms'

class ColumnSort extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    currentSort: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    currentSort: 'asc'
  }

  constructor (props) {
    super(props)
    this.state = {
      direction: this.props.currentSort
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentSort !== this.props.currentSort) {
      this.setState({ direction: this.props.currentSort })
    }
  }

  render () {
    const { label } = this.props
    const icon = this._getIcon()

    return (
      <div
        onClick={this._handleClick}
        className="column-sort"
      >
        <span className="column-sort__icon">{icon}</span>
        <span className="column-sort__label">{label}</span>
      </div>
    )
  }

  _handleClick = () => {
    const newDirection = this.state.direction === 'asc'
      ? 'desc'
      : 'asc'
    this.setState({ direction: newDirection }, () => {
      this.props.onChange(newDirection)
    })
  }

  _getIcon = () => {
    const { direction } = this.state
    const icon = direction === 'asc' ? 'arrow-up' : 'arrow-down'
    return <Icon name={icon} />
  }
}

export default ColumnSort

import * as pages from '../components/pages'
import modules from '../modules'

const routes = [
  {
    name: 'dashboard',
    path: '/',
    component: pages.Dashboard,
    requiresAuth: true
  },
  {
    name: 'admins',
    path: '/usuarios',
    component: pages.Admins,
    requiresAuth: true
  },
  {
    name: 'createAdmin',
    path: '/usuarios/criar',
    component: pages.CreateAdmin,
    requiresAuth: true
  },
  {
    name: 'editAdmin',
    path: '/usuarios/editar/:id',
    component: pages.EditAdmin,
    requiresAuth: true
  },
  {
    name: 'login',
    path: '/login',
    component: pages.Login
  },
  {
    name: 'logout',
    path: '/logout',
    component: pages.Logout,
    requiresAuth: true
  },
  {
    name: 'settings',
    path: '/configuracoes',
    component: pages.Settings,
    requiresAuth: true
  },
]

for (const index of Object.keys(modules)) {
  const item = modules[index]
  const { name } = item
  routes.push({
    name: `${name}List`,
    path: `/${item.slug}`,
    component: pages.ListGeneric({ item, modules }),
    requiresAuth: true
  })
  routes.push({
    name: `${name}Create`,
    path: `/${item.slug}/adicionar`,
    component: pages.CreateGeneric({ item, modules }),
    requiresAuth: true
  })
  routes.push({
    name: `${name}Edit`,
    path: `/${item.slug}/editar/:id`,
    component: pages.EditGeneric({ item, modules }),
    requiresAuth: true
  })
}

export default { routes }

import routes from '../routes'

export default {
  get (name, props = {}) {
    const link = routes.routes.find(item => item.name === name)
    if (link) {
      let { path } = link
      for (const field in props) {
        path = path.replace(`:${field}`, props[field])
      }
      return path
    }
  },
  getReverse (pathname) {
    const link = routes.routes.find(item => item.path === pathname)
    if (link) {
      return link.name
    }
  }
}

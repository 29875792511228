export default {
  getErrors (name, errors) {
    let output = []
    if (errors[name]) {
      output = errors[name]
    }
    return output
  },
  async fileToBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        resolve(e.target.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }
}

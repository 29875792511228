export const beforeSend = (identifier = null, settings = {}) => {
  return {
    type: 'BEFORE_SEND',
    identifier,
    ...settings
  }
}

export const afterResponse = (identifier = null, settings = {}) => {
  return {
    type: 'AFTER_RESPONSE',
    identifier,
    ...settings
  }
}

export const setPaging = paging => {
  return {
    type: 'SET_PAGING',
    paging
  }
}

export const setRedirect = data => {
  return {
    type: 'SET_REDIRECT',
    data
  }
}

export const setCleanRedirect = () => {
  return {
    type: 'SET_CLEAN_REDIRECT'
  }
}

export const setCookie = (name, value, action = 'set') => {
  return {
    type: 'SET_COOKIE',
    name,
    value,
    action
  }
}

export const setCleanCookie = (name, value) => {
  return {
    type: 'SET_CLEAN_COOKIE'
  }
}

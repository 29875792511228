import { uploadValidator as validator } from '../validators'

const fields = [
  {
    name: 'Descrição',
    field: 'description',
    type: 'text'
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  return {
    ...data,
    sort: +data.sort
  }
}

export default {
  singular: 'Upload',
  plural: 'Uploads',
  endpoint: 'uploads',
  name: 'uploads',
  slug: 'uploads',
  sortable: false,
  sortableField: 'title',
  filters,
  fields,
  validator,
  beforeSubmit
}

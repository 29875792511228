import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect,
  setCookie
} from './'

const identifier = 'admin'

const _setAdmin = admin => ({ type: 'SET_ADMIN', admin })
const _setAdminData = data => ({ type: 'SET_ADMIN_DATA', data })
const _setAdmins = data => ({ type: 'SET_ADMINS', data })
const _setLogout = () => ({ type: 'SET_LOGOUT' })

export const checkLogin = (token, screen = 'dashboard', screenParams = {}, location = null) => {
  return dispatch => {
    if (token) {
      dispatch(beforeSend(identifier))
      return api.post('admin/admins/check-token', { token })
        .then(result => {
          if (result.success) {
            dispatch(_setAdmin({
              ...result.data,
              token
            }))
            if (location) {
              dispatch(setRedirect({ location }))
            } else if (screen) {
              dispatch(setRedirect({ screen, params: screenParams }))
            }
          } else {
            dispatch(_setAdmin(null))
            dispatch(setCookie('token', null, 'remove'))
            if (screen || location) {
              dispatch(setRedirect({ screen: 'login' }))
            }
            dispatch(setCookie('token', null, 'remove'))
          }
          dispatch(afterResponse(identifier))
        })
        .catch(error => {
          dispatch(notificate(error.message, 'error'))
          dispatch(_setAdmin(null))
          dispatch(setCookie('token', null, 'remove'))
          if (screen || location) {
            dispatch(setRedirect({ screen: 'login' }))
          }
          dispatch(afterResponse(identifier))
        })
    } else {
      dispatch(_setAdmin(null))
      if (screen) {
        dispatch(setRedirect({ screen: 'login' }))
      }
      dispatch(afterResponse(identifier))
    }
  }
}

export const login = (data, screen = 'dashboard') => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/admins/login', data)
      .then(result => {
        if (result.success) {
          dispatch(_setAdmin({
            ...result.data.admin,
            token: result.data.token
          }))
          dispatch(setCookie('token', result.data.token))
          dispatch(setRedirect({ screen }))
        } else {
          dispatch(notificate(result.message, 'error'))
          dispatch(_setAdmin(null))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(_setAdmin(null))
        dispatch(afterResponse(identifier))
      })
  }
}

export const logout = token => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete('admin/admins/logout', { token }, token)
      .then(() => {
        dispatch(setCookie('token', null, 'remove'))
        dispatch(_setLogout())
        dispatch(setRedirect({ screen: 'login' }))
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(_setLogout())
        dispatch(afterResponse(identifier))
      })
  }
}

export const createAdmin = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/admins', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'admins' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateAdmin = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/admins/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'admins' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyAdmin = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/admins/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'admins' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadAdmin = (token, id) => {
  return dispatch => {
    dispatch(_setAdminData(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/admins/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setAdminData(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadAdmins = (token, params) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/admins', params, token)
      .then(result => {
        if (result.success) {
          dispatch(_setAdmins(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

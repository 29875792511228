import React from 'react'

import CopyWithInput from '../components/molecules/CopyWithInput'

import { Job } from '../entities'
import { jobValidator as validator } from '../validators'

const fields = [
  {
    name: 'Título',
    field: 'title',
    type: 'text',
    filter: (title, item) => {
      const link = new Job(item).getLink()

      return (
        <div>
          <p>{title}</p>
          <br />
          <CopyWithInput value={link} />
        </div>
      )
    }
  },
  {
    name: 'Descrição',
    field: 'description',
    type: 'texteditor',
    filter: value => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value
          }}
        ></div>
      )
    }
  },
  {
    name: 'Link do formulário do Google',
    field: 'apply_url',
    type: 'text',
  },
  {
    name: 'Ordem',
    field: 'sort',
    type: 'text',
  },
  {
    name: 'Textos (Responsabilidades, Desejável, etc)',
    field: 'job_contents',
    type: 'text',
    component: 'JobContentManager',
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  const newData = {
    ...data,
    sort: +data.sort,
    status: data.status || data.status === undefined
      ? 'enabled'
      : 'disabled',
  }
  return newData
}

const formatData = (data) => {
  return {
    ...data,
    status: data.status === 'enabled',
  }
}

export default {
  singular: 'Vaga',
  plural: 'Vagas',
  endpoint: 'jobs',
  name: 'jobs',
  slug: 'vagas',
  sortable: true,
  sortableField: 'title',
  listColumns: [
    'title',
    'description',
    'sort',
    'status',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { ClientLogo, Spinner } from '../molecules'
import { LoginForm } from '../organisms'
import { login, checkLogin } from '../../store/actions/admin'
import { notificate } from '../../store/actions/notification'

class Login extends Component {
  static defaultProps = {
    notificate: () => {},
    login: () => {}
  }

  componentDidMount () {
    const { admin } = this.props.admin
    const { checkLogin, cookies } = this.props
    if (!admin) {
      const token = cookies.get('token')
      const redirectTo = this.props.location.state
        ? this.props.location.state.lastLocation
        : '/'
      checkLogin(token, null, null, redirectTo)
    }
  }

  render () {
    const { sending, checked } = this.props.admin
    return (
      <div className="login-page">
        {sending && !checked &&
          <Spinner />
        }
        {(!sending || checked) &&
          <div className="login-page__center">
            <div className="login-page__center__logo">
              <ClientLogo />
            </div>
              <LoginForm
                sending={sending}
                notificate={this._onNotificate}
                login={this._onLogin}
              />
          </div>
        }
      </div>
    )
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }

  _onLogin = data => {
    let screen = 'dashboard'
    if (this.props.history.location.state) {
      screen = this.props.history.location.state.screen
    }
    this.props.login(data, screen)
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = {
  login,
  notificate,
  checkLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(Login)
)

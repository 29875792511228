import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadAdmin, updateAdmin } from '../../store/actions/admin'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { AdminForm } from '../organisms'

class EditAdmin extends Component {
  constructor(props) {
    super(props)
    const { token } = props.admin.admin
    const { id } = props.match.params
    props.loadAdmin(token, id)
  }

  render() {
    const { data, sending } = this.props.admin
    return (
      <div>
        <BackToList to='admins' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar Administrador</h1>
            {data &&
              <AdminForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='admin' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.updateAdmin(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadAdmin: (token, id) => dispatch(loadAdmin(token, id)),
    updateAdmin: (token, data) => dispatch(updateAdmin(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin)

import React from 'react'
import ImagePreview from '../components/molecules/ImagePreview'
import { partnerValidator as validator } from '../validators'

const fields = [
  {
    name: 'Logo',
    field: 'logo_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.logo_upload) {
        const thumbnail = row.logo_upload.thumb_url
        const image = row.logo_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.logo_upload ? model.logo_upload.url: null,
      }
    }
  },
  // {
  //   name: 'Imagem do Funcionário',
  //   field: 'image_upload_id',
  //   type: 'text',
  //   component: 'MediaLibrary',
  //   filter: (_, row) => {
  //     if (row.image_upload) {
  //       const thumbnail = row.image_upload.thumb_url
  //       const image = row.image_upload.url
  //       return (
  //         <ImagePreview
  //           thumbnail={thumbnail}
  //           image={image}
  //           alt={row.name}
  //           width="70"
  //           className="thumbnail"
  //         />
  //       )
  //     }
  //   },
  //   props({ model }) {
  //     return {
  //       preview: model.image_upload ? model.image_upload.url: null,
  //     }
  //   }
  // },
  {
    name: 'Nome',
    field: 'name',
    type: 'text'
  },
  // {
  //   name: 'Nome do Funcionário',
  //   field: 'client_name',
  //   type: 'text'
  // },
  // {
  //   name: 'Cargo do Funcionário',
  //   field: 'client_role',
  //   type: 'text'
  // },
  // {
  //   name: 'Testemunho do Funcionário',
  //   field: 'testimonial',
  //   type: 'text'
  // },
  {
    name: 'Ordem',
    field: 'sort',
    type: 'text'
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  }
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  const payload = {
    ...data,
    logo_upload_id: +data.logo_upload_id,
    image_upload_id: +data.logo_upload_id,
    sort: +data.sort,
    status: data.status || data.status === undefined
      ? 'enabled'
      : 'disabled',
  }
  delete payload.logo_upload
  delete payload.image_upload
  return payload
}

const formatData = (data) => {
  return {
    ...data,
    status: data.status === 'enabled'
  }
}

export default {
  singular: 'Parceiro',
  plural: 'Parceiros',
  endpoint: 'partners',
  name: 'partners',
  slug: 'parceiros',
  sortable: true,
  sortableField: 'name',
  listColumns: [
    'logo_upload_id',
    'name',
    'sort',
    'status',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData,
}

import React, { Component } from 'react'
import MediaLibrary from './MediaLibrary'

export default class StorePageBuilderHome extends Component {
  state = {
    model: {
      value: {},
    },
  }

  componentDidUpdate () {
    if (
      JSON.stringify(this.state.model.value) !== JSON.stringify(this.props.value)
      ) {
      const model = {
        value: this.props.value
      }
      this.setState({ model })
    }
  }

  render() {
    return (
      <div className="product-page product-page--home">
        <h2 className="page-title">Página Inicial</h2>
        <MediaLibrary
          preview={this._preview()}
          value={this._imageId()}
          label="Imagem"
          onChange={(value, upload) => {
            this._handleChange({
              image_upload_id: value,
              image_url: upload.url,
            })
          }}
          errors={[]}
        />
      </div>
    )
  }

  _preview = () => {
    const { value } = this.state.model
    return value.image_url
  }

  _imageId = () => {
    const { value } = this.state.model
    return value.image_upload_id
  }

  _handleChange = (payload) => {
    const model = {
      value: {
        ...this.state.model.value,
        ...payload,
      }
    }
    this.setState({ model }, () => {
      this.props.onChange(this.state.model.value)
    })
  }
}

import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {
  loadList,
  createItem,
  destroyItem
} from '../../store/actions/generic'
import uploadModule from '../../modules/uploads'
import { form } from '../../utils'
import { Icon, Button } from '../atoms'
import { ImagePreview, MediaLibraryItem } from '../molecules'
import domUtils from '../../utils/dom_utils'

class MediaLibrary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      opened: false,
      dragOver: false,
      upload: null,
      page: 1,
      error: null,
      uploading: false,
    }
  }

  render () {
    const { upload, dragOver, opened, uploading } = this.state
    const { label, preview } = this.props
    const { sending } = this.props.upload
    const errors = this._getErrors()
    const classes = classNames({
      'media-library': true,
      'media-library--uploading': uploading,
      'media-library--invalid': !!errors,
    })
    const libraryClasses = classNames({
      'media-library__content__library': true,
      'media-library__content__library--active': opened,
    })
    const classesUploadZone = classNames({
      'media-library__content__upload-zone': true,
      'media-library__content__upload-zone--drag-over': dragOver,
    })
    const isDisabledButton = this._isDisabledButton()
    let previewURL
    if (preview) {
      previewURL = preview
    }
    if (upload) {
      previewURL = upload.url
    }
    return (
      <div className={classes}>
        <div
           className="media-library__label"
        >{label}</div>
        <div className="media-library__content">
          {previewURL && (
            <div className="media-library__content__upload">
              <ImagePreview
                thumbnail={previewURL}
                image={previewURL}
                className="media-library__content__upload__image"
                alt="Preview"
              />
            </div>
          )}
          <div className="media-library__content__upload-library">
            <div
              className={classesUploadZone}
              onDrop={this._onDrop}
              onDragOver={this._onDragOver}
              onDragLeave={this._onDragEnd}
            >
              <h2 className="media-library__content__upload-zone__title">Solte o arquivo aqui</h2>
              <p className="media-library__content__upload-zone__subtitle">Ou clique para selecionar</p>
              <input
                type="file"
                className="media-library__content__upload-zone__input-file"
                onChange={this._onSelectFile}
              />
            </div>
            <div
              className="button button--small media-library__content__open-library"
              onClick={this._toggleModal}
            >
              <Icon name="picture" />
              <br />
              Biblioteca
              <br />
              de arquivos
            </div>
          </div>
          <div className={libraryClasses}>
            <div className="media-library__content__library__wrapper">
              <div className="media-library__content__library__header">
                <div className="media-library__content__library__header__title">
                  Biblioteca de arquivos
                </div>
                <div
                  className="media-library__content__library__header__close"
                  onClick={this._toggleModal}
                >
                  <Icon name="close" />
                </div>
              </div>
              <div className="media-library__content__library__list">
                {this._getMediaLibraryItems()}
              </div>
              <div className="media-library__content__library__actions">
                <Button
                  spinner={sending}
                  disabled={isDisabledButton}
                  size="small"
                  onClick={this._loadListNextPage}
                >Carregar mais</Button>
              </div>
            </div>
          </div>
        </div>
        {errors}
      </div>
    )
  }

  _toggleModal = () => {
    const opened = !this.state.opened
    this.setState({ opened }, () => {
      if (opened) {
        domUtils.addBodyClass('no-scroll')
        this._loadList()
      } else {
        domUtils.removeBodyClass('no-scroll')
      }
    })
  }

  _loadList = () => {
    const { page } = this.state
    const { token } = this.props.admin.admin
    this.props.loadList(uploadModule, token, {
      infinite_scroll: true,
      limit: 50,
      page
    })
  }

  _loadListNextPage = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this._loadList()
    })
  }

  _onDrop = (e) => {
    e.preventDefault()
    this.setState({ dragOver: false })
    const { files } = e.dataTransfer
    this._uploadFile(files[0])
  }

  _uploadFile = (file) => {
    this.setState({ uploading: true }, async () => {
      try {
        const { token } = this.props.admin.admin
        const base64 = await form.fileToBase64(file)
        const data = { base64 }
        const result = await this.props.createItem(uploadModule, token, data, {
          noRedirect: true
        })
        if (result.data.id) {
          this.setState({ upload: result.data, uploading: false }, () => {
            this.props.onChange(result.data.id, result.data)
          })
        }
      } catch (error) {
        this.setState({ error: error.message, uploading: false })
      }
    })
  }

  _onDragOver = (e) => {
    e.preventDefault()
    this.setState({ dragOver: true })
  }

  _onDragEnd = (e) => {
    e.preventDefault()
    this.setState({ dragOver: false })
  }

  _onSelectFile = (e) => {
    const { files } = e.target
    this._uploadFile(files[0])
  }

  _getErrors = () => {
    const errors = []
    if (this.props.errors.length > 0) {
      this.props.errors.forEach(item => errors.push(item))
    }
    if (this.state.error) {
      errors.push(this.state.error)
    }
    if (errors.length > 0) {
      const errorsItems = errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errorsItems}</ul>
    }
  }

  _getMediaLibraryItems = () => {
    const { list } = this.props.upload
    return list.map((item => {
      return (
        <MediaLibraryItem
          key={item.id}
          item={item}
          onSelect={this._onSelectMediaLibraryItem}
          onDestroy={this._onDestroyMediaLibraryItem}
        />
      )
    }))
  }

  _onSelectMediaLibraryItem = (item) => {
    this._toggleModal()
    this.setState({ upload: item }, () => {
      this.props.onChange(item.id, item)
    })
  }

  _onDestroyMediaLibraryItem = async (item) => {
    const { token } = this.props.admin.admin
    await this.props.destroyItem(uploadModule, token, item.id, {
      noRedirect: true
    })
  }

  _isDisabledButton = () => {
    const { sending, paging } = this.props.upload
    return (
      sending ||
      (paging && paging.pages === paging.page)
    )
  }
}

const mapStateToProps = ({ admin, generic }) => ({
  admin,
  upload: generic.modules.uploads,
})

const mapDispatchToProps = {
  loadList,
  createItem,
  destroyItem
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibrary)

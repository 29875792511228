import { storeCategoryValidator as validator } from '../validators'

const fields = [
  {
    name: 'Nome',
    field: 'name',
    type: 'text'
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  return {
    ...data
  }
}

export default {
  singular: 'Categoria de Produto',
  plural: 'Categorias de Produto',
  endpoint: 'store-categories',
  name: 'storeCategories',
  slug: 'categorias-de-produtos',
  sortable: false,
  sortableField: 'name',
  filters,
  fields,
  validator,
  beforeSubmit
}

import React, { Component } from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { connect } from 'react-redux'
import { Button, Link } from '../atoms'
import { FormControl, Spinner } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import { loadList, destroyItem, sortList } from '../../store/actions/generic'
import { links, navigate } from '../../utils'

const SortableItem = sortableElement(({ value, sort }) => (
  <li>
    <div
      className="list-sort__item"
      data-sort={sort}
    >
      {value}
    </div>
  </li>
))

const SortableContainer = sortableContainer(({ loading, children }) => {
  const classes = ['sortable-container']
  if (loading) {
    classes.push('sortable-container--loading')
  }
  return <ul className={classes.join(' ')}>{children}</ul>;
})

class ListGeneric extends Component {
  state = {
    filters: {
      page: 1,
      limit: 50,
      search: ''
    },
    currentId: null,
    sortActived: false
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { item } = this.props
    const { sortable, sortableField, disableAdd, disableFilters } = item
    const { list, paging, sending, filters } = this.props.generic.modules[item.name]
    const { currentId, sortActived } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          {!disableAdd && (
            <div className="top-actions__item">
              <Link
                button
                disabled={sending}
                size='small'
                to={links.get(`${item.name}Create`)}
              >
                + Criar {item.singular}
              </Link>
            </div>
          )}
          {sortable &&
            <div className="top-actions__item">
              <FormControl
                label="Ordenar registros"
                type="switch"
                checked={sortActived}
                onChange={this._handleToggleSort}
              />
            </div>
          }
        </div>
        {!sortActived && !disableFilters &&
          <ListFilters
            onChange={this._onFilter}
            data={filters}
            filters={item.filters}
          />
        }
        {!sortActived && sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        {!sortActived &&
          <DataTable
            loading={sending}
            columns={columns}
            rows={this._formatList(list)}
            paging={paging}
            onEdit={this._onEdit}
            onDelete={this._onDelete()}
            onChangePage={this._onChangePage}
          />
        }
        {sortableField && sortActived &&
          <div className="wrapper">
            {sortActived && (
              <React.Fragment>
                <Button
                  size="small"
                  onClick={this._setAlphabeticalOrder}
                >Colocar em ordem alfabética</Button>
                <br />
                <br />
              </React.Fragment>
            )}
            <div className="list-sort">
              <SortableContainer
                onSortEnd={this._onSortEnd}
                loading={sending}
                lockAxis="y"
              >
                {this._sortList(list).map((item, index) => {
                  let value = item[sortableField]
                  if (typeof sortableField === 'function') {
                    value = sortableField(item)
                  }
                  return (
                    <SortableItem
                      key={`item-${item.id}`}
                      index={index}
                      sort={+index + 1}
                      value={value}
                    />
                  )
                })}
              </SortableContainer>
            </div>
          </div>
        }
      </div>
    )
  }

  _loadData = () => {
    const { item } = this.props
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadList(item, token, filters)
  }

  _getColumns = () => {
    const { fields, listColumns } = this.props.item
    return (listColumns || fields.map(item => item.field))
      .map((field) => fields.find((item) => item.field === field))
      .filter((v) => v)
  }

  _onEdit = record => {
    const { item } = this.props
    navigate.to({
      screen: `${item.name}Edit`,
      params: { id: record.id },
      props: this.props
    })
  }

  _onDelete = () => {
    const { disableDelete } = this.props.item
    if (disableDelete) return undefined
    return record => {
      this.setState({ currentId: record.id })
    }
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { item } = this.props
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyItem(item, token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    const { item } = this.props
    if (item.formatList) {
      return item.formatList(list)
    }
    return list
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    const { item } = this.props
    if (this.props.generic.modules[item.name].filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.generic.modules[item.name].filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _handleToggleSort = () => {
    const sortActived = !this.state.sortActived
    const filters = {
      ...this.state.filters
    }
    if (sortActived) {
      filters.search = ''
      filters.limit = 10000
    } else {
      filters.limit = 10
    }
    this.setState({ sortActived, filters }, () => {
      this._loadData()
    })
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    const { item } = this.props
    const { token } = this.props.admin.admin
    const { list } = this.props.generic.modules[item.name]
    const ids = arrayMove(list, oldIndex, newIndex)
      .map((item, index) => ({ id: item.id, sort: index + 1 }))
    this.props.sortList(item, token, ids)
  }

  _sortList = (list) => {
    return list.sort((a, b) => {
      if (a.sort < b.sort) return -1
      if (a.sort > b.sort) return 1
      return 0
    })
  }

  _setAlphabeticalOrder = () => {
    const { item } = this.props
    const { sortableField, sortableFieldAlphabeticalOrder } = item
    const { list } = this.props.generic.modules[item.name]
    const sortedList = [...list]
    const field = sortableFieldAlphabeticalOrder
      ? sortableFieldAlphabeticalOrder
      : sortableField
    const normalizeValue = v => v.toLowerCase().trim()
    sortedList.sort((a, b) => {
      if (normalizeValue(a[field]) < normalizeValue(b[field])) return -1
      if (normalizeValue(a[field]) > normalizeValue(b[field])) return 1
      return 0
    })
    const ids = sortedList
      .map((item, index) => ({ id: item.id, sort: index + 1 }))
    const { token } = this.props.admin.admin
    this.props.sortList(item, token, ids)
  }
}

const mapStateToProps = ({ admin, generic }) => ({
  admin,
  generic
})

const mapDispatchToProps = {
  loadList,
  destroyItem,
  sortList
}

export default function ({ item }) {
  const Wrapper = function ({ ...props }) {
    return (
      <ListGeneric
        {...props}
        item={item}
      />
    )
  }
  return connect(mapStateToProps, mapDispatchToProps)(Wrapper)
}

import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'projects'

const roninValidator = {
  create(data) {
    const schema = {
      project_category_id: v8n().not.null().number().positive(),
      home_upload_id: v8n().not.null().number().positive(),
      mobile_upload_id: v8n().not.null().number().positive(),
      desktop_upload_id: v8n().not.null().number().positive(),
      header_upload_id: v8n().not.null().number().positive(),
      client: v8n().not.null().minLength(3).maxLength(255),
      title: v8n().not.null().minLength(3).maxLength(255),
      slug: v8n().not.null().minLength(3).maxLength(255),
      description: v8n().not.null().minLength(3),
      sort: v8n().not.null().number().positive(),
      project_media: v8n().not.null().array().minLength(1),
    }
    return v8n()
      .schema(schema)
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => {
        const out = validatorUtils.buildErrors(identifier, e)
        console.log('e', e);
        console.log('out', out);
        return out
      })
  },

  edit(data) {
    return roninValidator.create(data)
  }
}

export default roninValidator

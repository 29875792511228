import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { form } from '../../utils'
import MediaLibrary from './MediaLibrary'
import ProjectMediaManager from './ProjectMediaManager'
import ProjectLinkManager from './ProjectLinkManager'
import ProjectTextManager from './ProjectTextManager'
import ProjectListType from './ProjectListType'
import JobContentManager from './JobContentManager'
import StoreProductVariationManager from './StoreProductVariationManager'
import MultipleImageManager from './MultipleImageManager'
import StorePageBuilder from './StorePageBuilder'

export default class GenericForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    options: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired,
    validator: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.object
    ),
    beforeSubmit: PropTypes.func,
    formatData: PropTypes.func
  }

  state = {
    model: {},
    errors: {}
  }

  componentDidMount() {
    const { formatData, data } = this.props
    if (data) {
      let model = {
        ...this.state.model,
        ...data
      }
      if (formatData) {
        model = formatData(model)
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields, options } = this.props
    const { model, errors } = this.state
    const items = fields
      .map((item, index) => {
        const { group, hidden } = item
        if (hidden) return false
        let value = model[item.field] !== undefined
          ? model[item.field]
          : ''
        let otherProps = {}
        if (item.props) {
          otherProps = item.props.apply(this, [{ props: this.props, model }])
        }
        if (item.component) {
          if (item.component === 'MediaLibrary') {
            return {
              group,
              component: (
                <MediaLibrary
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'ProjectLinkManager') {
            return {
              group,
              component: (
                <ProjectLinkManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'ProjectTextManager') {
            return {
              group,
              component: (
                <ProjectTextManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'ProjectListType') {
            return {
              group,
              component: (
                <ProjectListType
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'ProjectMediaManager') {
            return {
              group,
              component: (
                <ProjectMediaManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'JobContentManager') {
            return {
              group,
              component: (
                <JobContentManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'StoreProductVariationManager') {
            return {
              group,
              component: (
                <StoreProductVariationManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'MultipleImageManager') {
            return {
              group,
              component: (
                <MultipleImageManager
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          } else if (item.component === 'StorePageBuilder') {
            return {
              group,
              component: (
                <StorePageBuilder
                  {...otherProps}
                  key={index}
                  label={item.name}
                  value={value}
                  onChange={this._onChange(item.field)}
                  errors={form.getErrors(item.field, errors)}
                />
              )
            }
          }
        }
        if (item.type === 'select' && !item.options) {
          otherProps.options = [
            {
              label: 'Selecione',
              value: ''
            }
          ].concat(
            options[item.loadOptions] || []
          )
        }

        return {
          group,
          component: (
            <FormControl
              {...otherProps}
              key={index}
              type={item.type}
              label={item.name}
              mask={item.mask}
              value={value}
              onChange={this._onChange(item.field)}
              errors={form.getErrors(item.field, errors)}
            />
          )
        }
      })
      .reduce((acc, item) => {
        const group = item.group || '0_no_group'
        if (!acc[group]) {
          acc[group] = []
        }
        acc[group].push(item.component)
        return acc
      }, {})
    return Object.keys(items)
      .sort((a, b) => {
        const aValue = +a.split('_').shift()
        const bValue = +b.split('_').shift()
        if (aValue < bValue) return -1
        if (aValue > bValue) return 1
        return 0
      })
      .map((group, index) => {
        const classes = classNames({
          'form-control-group': group !== '0_no_group',
          [`form-control-group--${group}`]: group !== '0_no_group',
        })
        return (
          <div
            className={classes}
            key={index}
          >{items[group]}</div>
        )
      })
  }

  _handleSubmit = async event => {
    event.preventDefault()
    try {
      const { validator, beforeSubmit } = this.props
      const { model } = this.state
      const method = !this.props.data ? 'create' : 'edit'
      let data = {
        ...model
      }
      if (beforeSubmit) {
        data = await beforeSubmit(data, method)
      }
      validator[method](data).then(result => {
        this.setState({ errors: {} })
        if (result.success) {
          this.props.onSubmit(data)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    } catch (error) {
      this.props.notificate(error.message)
    }
  }

  _onChange = field => {
    const blockFields = []
    return value => {
      if (!blockFields.includes(field)) {
        const model = {
          ...this.state.model,
          [field]: value
        }
        this.setState({ model })
      }
    }
  }
}

import React, { Component } from 'react'
import { StorePageBuilderAbout } from '.'
import StorePageBuilderHome from './StorePageBuilderHome'

export default class StorePageBuilder extends Component {
  state = {
    model: {
      value: {},
    },
  }

  componentDidUpdate () {
    if (
      JSON.stringify(this.state.model.value) !== JSON.stringify(this.props.value)
      ) {
      const model = { value: this.props.value }
      this.setState({ model })
    }
  }

  render() {
    const classes = this._getClasses()
    return (
      <div className={classes}>
        <div className="project-relation-manager__list">
          {this._renderItems()}
        </div>
        {this._getErrors()}
      </div>
    )
  }

  _renderItems = () => {
    const { slug, value } = this.props

    switch (slug) {
      case 'home':
        return (
          <StorePageBuilderHome
            value={value}
            onChange={this._onChange}
          />
        )

      case 'about':
        return (
          <StorePageBuilderAbout
            value={value}
            onChange={this._onChange}
          />
        )

      default:
        return null
    }
  }

  _getClasses = () => {
    let classes = ['project-relation-manager']
    if (this.props.errors.length > 0) {
      classes.push('project-relation-manager--error')
    }
    return classes.join(' ')
  }

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  }

  _onChange = (value) => {
    const model = { value }
    this.setState({ model }, () => {
      this.props.onChange(value)
    })
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner, ModuleCount } from '../molecules'
import { loadDahsboard } from '../../store/actions/dashboard'

class Dashboard extends Component {
  componentDidMount () {
    const { token } = this.props.admin.admin
    this.props.loadDahsboard(token)
  }

  render () {
    const { sending } = this.props.dashboard
    const items = this._getItems()
    return (
      <div>
        <h1 className="page-title page-title--bold">Resumo</h1>
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        {!sending &&
          <div className="dashboard-items-charts">
            <div className="dashboard-items">
              {items}
            </div>
          </div>
        }
      </div>
    )
  }

  _getItems = () => {
    const { data, sending } = this.props.dashboard
    if (sending || !data.counter) {
      return false
    }
    return data.counter.map((item, index) => {
      return (
        <ModuleCount
          key={index}
          item={item}
        />
      )
    })
  }

  _getChartData = () => {
    const { data, sending } = this.props.dashboard
    if (sending || !data.chart) {
      return []
    }
    return data.chart
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    dashboard: state.dashboard
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDahsboard: token => dispatch(loadDahsboard(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

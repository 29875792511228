import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createAdmin } from '../../store/actions/admin'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { AdminForm } from '../organisms'

class CreateAdmin extends Component {
  render() {
    const { sending } = this.props.admin
    return (
      <div>
        <BackToList to='admins' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Administrador</h1>
            <AdminForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='admin' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.createAdmin(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createAdmin: (token, data) => dispatch(createAdmin(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin)

import React, { Component } from 'react'
import clientLogoSource from '../../assets/svg/client-logo.svg'

export default class ClientLogo extends Component {
  render () {
    return (
      <div className="client-logo">
        <div className="client-logo__image">
          <img src={clientLogoSource} alt='Logo' />
        </div>
      </div>
    )
  }
}

import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'jobs'

const jobValidator = {
  create(data) {
    const schema = {
      title: v8n().not.null().minLength(3).maxLength(255),
      description: v8n().not.null().minLength(3),
      apply_url: v8n().not.null().minLength(3).maxLength(255),
      sort: v8n().not.null().number().positive(),
      job_contents: v8n().not.null().array().minLength(1),
    }
    return v8n()
      .schema(schema)
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },

  edit(data) {
    return jobValidator.create(data)
  }
}

export default jobValidator

import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'teamMembers'

const teamMemberValidator = {
  create(data) {
    return v8n()
      .schema({
        upload_id: v8n().not.null().number().positive(),
        name: v8n().not.null().minLength(3).maxLength(255),
        role: v8n().not.null().minLength(3).maxLength(255),
        sort: v8n().not.null().number().positive(),
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },
  edit(data) {
    return this.create(data)
  },
}

export default teamMemberValidator

import React, { Component } from 'react'
import classNames from 'classnames'

export default class ProjectListType extends Component {
  state = {
    model: {
      value: ''
    },
  }

  componentDidUpdate () {
    if (
      this.props.value !== undefined &&
      this.props.value !== '' &&
      this.state.model.value !== this.props.value
    ) {
      const model = {
        ...this.state.model,
        value: this.props.value
      }
      this.setState({ model })
    }
  }

  render() {
    const { label } = this.props
    return (
      <div className="project-list-type">
        <label className="project-list-type__label form__label form__label--small">{label}</label>
        <div className="project-list-type__options">
          {this._getItems()}
        </div>
        {this._getErrors()}
      </div>
    )
  }

  _getItems = () => {
    const { value } = this.state.model
    const values = [
      {
        value: 0,
        label: 'Imagem 70%, Texto direita 30%',
      },
      {
        value: 1,
        label: 'Imagem 30%, Texto direita 20%',
      },
      {
        value: 2,
        label: 'Imagem 30%, Texto esquerda 20%',
      },
      {
        value: 3,
        label: 'Imagem 80%, Texto direita 20%',
      },
      {
        value: 4,
        label: 'Imagem 70%, Texto esquerda 30%',
      },
      {
        value: 5,
        label: 'Imagem 20%, Texto esquerda 15%',
      },
      {
        value: 6,
        label: 'Imagem 45%, Texto direita 20%',
      },
      {
        value: 7,
        label: 'Imagem 80%, Texto esquerda 20%',
      },
    ]
    return values.map((item) => {
      const classes = classNames({
        'project-list-type__options__item': true,
        [`project-list-type__options__item--variation-${item.value}`]: true,
        'project-list-type__options__item--selected': item.value === value,
      })
      return (
        <div
          key={item.value}
          className={classes}
          onClick={() => this._selectItem(item)}
        >
          <div className="project-list-type__options__item__icon">
            <span></span>
            <span></span>
          </div>
          <div className="project-list-type__options__item__label">
            {item.label}
          </div>
        </div>
      )
    })
  }

  _selectItem = (item) => {
    const model = {
      ...this.state.model,
      value: item.value
    }
    this.setState({ model }, () => {
      this.props.onChange(model.value)
    })
  }

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  }
}

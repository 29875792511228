import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FormTips extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired
  }

  render() {
    const content = this._getContent()
    if (!content) {
      return false
    }
    return (
      <div className="form-tips">
        <h2 className="page-title">Recomendações</h2>
        <div className="form-tips__content">
          {content}
        </div>
      </div>
    )
  }

  _getContent = () => {
    const { name } = this.props
    const items = {
      modality: [
        'Campo nome limitar até 40 caracteres;',
        'O nome do ícone é o nome usado no front-end;',
      ]
    }
    const item = items[name]
    if (item) {
      return item.map((value, index) => {
        return <p key={index}>{value}</p>
      })
    }
  }
}

import React, { Component } from 'react'
import classNames from 'classnames'
import { Icon, Button } from '../atoms'

export default class MediaLibraryItem extends Component {
  state = {
    deleting: false,
  }

  render () {
    const { item } = this.props
    const { deleting } = this.state
    const classes = classNames({
      'media-library-item': true,
      'media-library-item--deleting': deleting,
    })
    return (
      <div className={classes}>
        <div
          className="media-library-item__media"
          onClick={this._onSelect}
        >
          <img
            className="thumbnail"
            src={item.thumb_url}
            alt={item.name}
          />
        </div>
        {!deleting && (
          <div
            className="media-library-item__destroy"
            onClick={this._onDestroy}
          >
            <Icon name="trash" />
          </div>
        )}
        {deleting && (
          <div
            className="media-library-item__confirm-destroy"
          >
            <Button
              intent="danger"
              size="small"
              onClick={this._onDestroy}
            >Remover</Button>
            <Button
              size="small"
              onClick={this._onCancelDestroy}
            >Cancelar</Button>
          </div>
        )}
      </div>
    )
  }

  _onSelect = () => {
    const { item } = this.props
    this.props.onSelect(item)
  }

  _onDestroy = () => {
    if (this.state.deleting) {
      const { item } = this.props
      this.props.onDestroy(item)
    } else {
      this.setState({ deleting: true })
    }
  }

  _onCancelDestroy = () => {
    this.setState({ deleting: false })
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '../atoms'

class SortChanger extends Component {
  static propTypes = {
    hasUp: PropTypes.bool,
    hasDown: PropTypes.bool,
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  }

  render () {
    const upClasses = classNames({
      'sort-changer__button': true,
      'sort-changer__button--disabled': !this.props.hasUp
    })
    const downClasses = classNames({
      'sort-changer__button': true,
      'sort-changer__button--disabled': !this.props.hasDown
    })
    return (
      <div className="sort-changer">
        <div className={upClasses} onClick={this._goUp}>
          <Icon name="arrow-up" />
        </div>
        <div className={downClasses} onClick={this._goDown}>
          <Icon name="arrow-down" />
        </div>
      </div>
    )
  }

  _goUp = () => {
    const { value } = this.props
    const newValue = value - 1
    if (newValue >= 0) {
      this.props.onChange(newValue)
    }
  }

  _goDown = () => {
    const { value, max } = this.props
    const newValue = value + 1
    if (newValue <= max) {
      this.props.onChange(newValue)
    }
  }
}

export default SortChanger

import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

class Link extends Component {
  static propTypes = {
    to: PropTypes.string,
    button: PropTypes.bool,
    size: PropTypes.string
  }

  static defaultProps = {
    to: '#'
  }

  render () {
    const {
      to,
      button,
      size
    } = this.props
    let classes = ['link']
    if (button) {
      classes.push('link--button')
      classes.push('button')
    }
    if (size) {
      classes.push(`link--${size}`)
      if (button) {
        classes.push(`button--${size}`)
      }
    }
    const safeProps = this._safeProps()
    return (
      <RouterLink
        {...safeProps}
        to={to}
        className={classes.join(' ')}
      >{this.props.children}</RouterLink>
    )
  }

  _safeProps = () => {
    let safeProps = { ...this.props }
    delete safeProps.to
    delete safeProps.button
    delete safeProps.size
    return safeProps
  }
}

export default Link

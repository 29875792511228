import React from 'react'
import ImagePreview from '../components/molecules/ImagePreview'
import { storeProductValidator as validator } from '../validators'

const fields = [
  {
    name: 'Imagem principal',
    field: 'image_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.image) {
        const thumbnail = row.image.thumb_url
        const image = row.image.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.image ? model.image.url: null,
      }
    }
  },
  {
    name: 'Categoria',
    field: 'store_category_id',
    type: 'select',
    loadOptions: 'storeCategories',
    filter: (_, row) => {
      return row.store_category
        ? row.store_category.name
        : null
    }
  },
  {
    name: 'Nome do produto',
    field: 'name',
    type: 'text'
  },
  {
    name: 'Descrição',
    field: 'description',
    type: 'texteditor'
  },
  {
    name: 'Preço',
    field: 'price',
    type: 'text',
    mask: 'money'
  },
  {
    name: 'Variações',
    field: 'store_product_variations',
    type: 'text',
    component: 'StoreProductVariationManager',
  },
  {
    name: 'Imagens',
    field: 'store_product_images',
    type: 'text',
    component: 'MultipleImageManager'
  },
  {
    name: 'Limitar em 1',
    field: 'limit_one',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.limit_one === undefined
          ? true
          : (model.limit_one === true)
      }
    }
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  },
]

const filters = ['search']

const convertStatusBooleanToText = (status: boolean | undefined): string => {
  return status || status === undefined
    ? 'enabled'
    : 'disabled'
}

const beforeSubmit = async function (data, method) {
  const newData = {
    ...data,
    store_category_id: +data.store_category_id,
    list_type: +data.list_type,
    sort: +(data.sort || 0),
    status: convertStatusBooleanToText(data.status),
    store_product_variations: (data.store_product_variations || [])
      .map((item) => ({
        id: item.id,
        name: item.name,
        sku: item.sku,
        status: convertStatusBooleanToText(item.status),
      })),
    store_product_images: (data.store_product_images || [])
      .map((item) => ({
        id: item.id,
        image_upload_id: item.image_upload_id,
        sort: +(item.sort || 0)
      })),
  }
  delete newData.store_category
  delete newData.image
  return newData
}

const formatData = (data) => {
  return {
    ...data,
    price: +(data.price || 0),
    status: data.status === 'enabled',
  }
}

export default {
  singular: 'Produto',
  plural: 'Produtos',
  endpoint: 'store-products',
  name: 'storeProducts',
  slug: 'produtos',
  sortable: false,
  sortableFieldAlphabeticalOrder: 'name',
  sortableField: (row) => {
    const category = row.store_category
      ? row.store_category.name
      : 'Sem categoria'
    return `${category} / ${row.name}`
  },
  listColumns: [
    'store_category_id',
    'image_upload_id',
    'name',
    'price',
    'sort',
    'status',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData
}

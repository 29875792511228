import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Badge extends Component {
  static propTypes = {
    intent: PropTypes.string
  }

  render () {
    const className = this._getClasses()
    return (
      <div className={className}>
        {this.props.children}
      </div>
    )
  }

  _getClasses = () => {
    const { intent, className } = this.props
    let classes = ['badge']
    if (intent) classes.push(`badge--${intent}`)
    if (className) classes.push(className)
    return classes.join(' ')
  }
}

export default Badge

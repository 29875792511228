import { combineReducers } from 'redux'
import admin from './admin'
import notification from './notification'
import base from './base'
import dashboard from './dashboard'
import setting from './setting'
import generic from './generic'

export default combineReducers({
  admin,
  notification,
  base,
  dashboard,
  setting,
  generic,
})

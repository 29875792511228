import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Spinner } from '../molecules'

class SettingItem extends Component {
  static props = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    public: PropTypes.boolean,
    sending: PropTypes.boolean,
    type: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      newValue: props.value
    }
  }

  render () {
    const otherProps = {}
    const { newValue } = this.state
    const { sending } = this.props
    const classes = this._getClasses()
    const description = this._getDescription()
    const type = this.props.type ? this.props.type : 'text'
    if (type === 'switch') {
      otherProps.checked = newValue === 'true' || +newValue === 1
    }
    return (
      <div className={classes}>
        <FormControl
          label={description}
          value={newValue}
          disabled={sending}
          onChange={this._onChange}
          onKeyUp={this._checkEnter}
          onBlur={this._sendChanges}
          type={type}
          {...otherProps}
        />
        {sending &&
          <Spinner />
        }
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['setting-item']
    return classes.join(' ')
  }

  _onChange = newValue => {
    this.setState({ newValue }, () => {
      if (this.props.type === 'switch') {
        this._sendChanges()
      }
    })
  }

  _checkEnter = event => {
    if (event.keyCode === 13) {
      this._sendChanges()
    }
  }

  _sendChanges = () => {
    const { newValue } = this.state
    const { id, name, description, value: oldValue } = this.props
    if (newValue !== oldValue) {
      this.props.onChange({
        id,
        name,
        description,
        value: newValue
      })
    }
  }

  _getDescription = () => {
    const { description } = this.props
    const parts = description.split(' - ')
    parts.shift()
    return parts.join(' - ').trim()
  }
}

export default SettingItem

import React from 'react'
import ImagePreview from '../components/molecules/ImagePreview'
import { teamMemberValidator as validator } from '../validators'

const fields = [
  {
    name: 'Imagem',
    field: 'upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.upload) {
        const thumbnail = row.upload.thumb_url
        const image = row.upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.upload ? model.upload.url: null,
      }
    }
  },
  {
    name: 'Nome',
    field: 'name',
    type: 'text'
  },
  {
    name: 'Cargo',
    field: 'role',
    type: 'text'
  },
  {
    name: 'Ordem',
    field: 'sort',
    type: 'text'
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  }
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  const payload = {
    ...data,
    upload_id: +data.upload_id,
    sort: +data.sort,
    status: data.status || data.status === undefined
      ? 'enabled'
      : 'disabled',
  }
  delete payload.upload
  return payload
}

const formatData = (data) => {
  return {
    ...data,
    status: data.status === 'enabled'
  }
}

export default {
  singular: 'Membro da Equipe',
  plural: 'Membros da Equipe',
  endpoint: 'team/members',
  name: 'teamMembers',
  slug: 'equipe',
  sortable: true,
  sortableField: 'name',
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData,
}

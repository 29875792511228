import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AgencyLogo, ClientLogo, HeaderMenu } from '../molecules'
import { links } from '../../utils'
import { translate } from '../../locales'

export default class Header extends Component {
  static propTypes = {
    admin: PropTypes.object
  };

  render () {
    const { admin } = this.props
    const classes = this._headerClasses()
    return (
      <header className={classes}>
        <div className="main-header__first">
          <div className="main-header__first__left">
            <div className="main-header__agency">
              <div className="main-header__agency__logo">
                <AgencyLogo />
              </div>
              <div className="main-header__agency__title">
                {translate('header.controlPanel')}
              </div>
            </div>
            <div className="main-header__client">
              <Link to={links.get('dashboard')}>
                <ClientLogo />
              </Link>
            </div>
          </div>
          <div className="main-header__first__right">
            {admin &&
              <div className="main-header__admin">
                <div className="main-header__admin__name_and_logout">
                  <div className="main-header__admin__name">
                    {admin.name}
                  </div>
                  <Link
                    to={links.get('logout')}
                    className="main-header__admin__logout"
                  >
                    Desconectar
                  </Link>
                </div>
                <div className="main-header__admin__avatar">
                  <div className="avatar">{admin.name[0]}</div>
                </div>
              </div>
            }
          </div>
        </div>
        {admin &&
          <div className="main-header__second">
            <HeaderMenu {...this.props} />
            <div className="main-header__modules">
              Módulos
            </div>
            <div className="main-header__welcome">
              Olá, Bem-vindo {admin.name}
            </div>
          </div>
        }
      </header>
    )
  }

  _headerClasses = () => {
    const { admin } = this.props
    let classes = ['main-header']
    if (admin) {
      classes.push('main-header--logged')
    }
    return classes.join(' ')
  }
}

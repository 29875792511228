import React from 'react'
import ImagePreview from '../components/molecules/ImagePreview'
import { projectValidator as validator } from '../validators'

const fields = [
  {
    name: 'Imagem 1 da home',
    field: 'home_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.home_upload) {
        const thumbnail = row.home_upload.thumb_url
        const image = row.home_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.home_upload ? model.home_upload.url: null,
      }
    }
  },
  {
    name: 'Imagem 2 da home',
    field: 'home_second_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.home_second_upload) {
        const thumbnail = row.home_second_upload.thumb_url
        const image = row.home_second_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.home_second_upload ? model.home_second_upload.url: null,
      }
    }
  },
  {
    name: 'Imagem da lista Mobile',
    field: 'mobile_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.mobile_upload) {
        const thumbnail = row.mobile_upload.thumb_url
        const image = row.mobile_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.mobile_upload ? model.mobile_upload.url: null,
      }
    }
  },
  {
    name: 'Imagem da lista Desktop',
    field: 'desktop_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.desktop_upload) {
        const thumbnail = row.desktop_upload.thumb_url
        const image = row.desktop_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.desktop_upload ? model.desktop_upload.url: null,
      }
    }
  },
  {
    name: 'Imagem principal',
    field: 'header_upload_id',
    type: 'text',
    component: 'MediaLibrary',
    filter: (_, row) => {
      if (row.header_upload) {
        const thumbnail = row.header_upload.thumb_url
        const image = row.header_upload.url
        return (
          <ImagePreview
            thumbnail={thumbnail}
            image={image}
            alt={row.name}
            width="70"
            className="thumbnail"
          />
        )
      }
    },
    props({ model }) {
      return {
        preview: model.header_upload ? model.header_upload.url: null,
      }
    }
  },
  {
    name: 'Categoria',
    field: 'project_category_id',
    type: 'select',
    loadOptions: 'projectCategories',
    filter: (_, row) => {
      return row.project_category
        ? row.project_category.name
        : null
    }
  },
  {
    name: 'Layout na lista',
    field: 'list_type',
    type: 'text',
    component: 'ProjectListType',
  },
  {
    name: 'Cliente',
    field: 'client',
    type: 'text'
  },
  {
    name: 'Título do projeto',
    field: 'title',
    type: 'text',
    props () {
      return {
        helpText: 'Para quebrar linha na lista de projetos, utilize o caracter "|". Exemplo: Linha 1 | Linha 2'
      }
    }
  },
  {
    name: 'Slug',
    field: 'slug',
    type: 'slug',
    props ({ model }) {
      return {
        mirror: model.title,
      }
    }
  },
  {
    name: 'Descrição',
    field: 'description',
    type: 'texteditor'
  },
  {
    name: 'Ordem',
    field: 'sort',
    type: 'text',
  },
  {
    name: 'Textos (Insight, Idea, Impact, etc)',
    field: 'project_texts',
    type: 'text',
    component: 'ProjectTextManager',
  },
  {
    name: 'Na imprensa',
    field: 'project_links',
    type: 'text',
    component: 'ProjectLinkManager',
  },
  {
    name: 'Mídia',
    field: 'project_media',
    type: 'text',
    component: 'ProjectMediaManager'
  },
  {
    name: 'Ativo',
    field: 'status',
    type: 'switch',
    filter: value => value === 'enabled' ? 'Sim' : 'Não',
    props ({ model }) {
      return {
        checked: model.status === undefined
          ? true
          : (model.status === true)
      }
    }
  },
]

const filters = ['search']

const beforeSubmit = async function (data, method) {
  const newData = {
    ...data,
    project_type: 1,
    project_category_id: +data.project_category_id,
    list_type: +data.list_type,
    sort: +data.sort,
    status: data.status || data.status === undefined
      ? 'enabled'
      : 'disabled',
  }
  delete newData.project_category
  delete newData.home_upload
  delete newData.home_second_upload
  delete newData.mobile_upload
  delete newData.desktop_upload
  delete newData.header_upload
  return newData
}

const formatData = (data) => {
  return {
    ...data,
    status: data.status === 'enabled',
  }
}

const listExtraParams = {
  project_type: 1,
}

const showExtraParams = listExtraParams

export default {
  singular: 'Projeto',
  plural: 'Projetos',
  endpoint: 'projects',
  name: 'projects',
  slug: 'projetos',
  sortable: true,
  sortableFieldAlphabeticalOrder: 'title',
  sortableField: (row) => {
    return `${row.client} / ${row.title}`
  },
  listColumns: [
    'home_upload_id',
    'home_second_upload_id',
    'project_category_id',
    'client',
    'title',
    'sort',
    'status',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData,
  listExtraParams,
  showExtraParams,
}

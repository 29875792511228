import React, { Component } from 'react'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Button, Icon } from '../atoms'
import { FormControl } from '../molecules'

const DragHandle = sortableHandle(() => (
  <span className="project-relation-manager__item__drag-handle">
    <Icon name="drag-handle" />
  </span>
))

const SortableItem = sortableElement(({ children }) => (
  <li>
    {children}
  </li>
))

const SortableContainer = sortableContainer(({ children }) => {
  const classes = ['sortable-container']
  return <ul className={classes.join(' ')}>{children}</ul>;
})

export default class JobContentManager extends Component {
  state = {
    model: {
      items: []
    },
  }

  componentDidUpdate () {
    if (
      Array.isArray(this.props.value) &&
      this.state.model.items.length !== this.props.value.length
    ) {
      const model = {
        ...this.state.model,
        items: this.props.value
      }
      this.setState({ model })
    }
  }

  render() {
    const { label } = this.props
    const classes = this._getClasses()
    const items = this._renderItems()
    return (
      <div className={classes}>
        <label className="project-relation-manager__title">{label}</label>
        <div className="project-relation-manager__list">
          <SortableContainer
            onSortEnd={this._onSortEnd}
            useDragHandle
            lockAxis="y"
          >
            {items}
          </SortableContainer>
        </div>
        <div className="project-relation-manager__actions">
          <Button
            size="small"
            onClick={this._addItem}
          >Adicionar item</Button>
        </div>
        {this._getErrors()}
      </div>
    )
  }

  _renderItems = () => {
    const { items } = this.state.model
    const columnOptions = [
      { value: 0, label: 'Esq.' },
      { value: 1, label: 'Dir.' },
    ]
    return items.map((item, index) => {
      return (
        <SortableItem
          key={`item-${index}`}
          index={index}
        >
          <div
            className="project-relation-manager__item"
          >
            <div className="project-relation-manager__item__control">
              <div className="project-relation-manager__item__group">
                <div className="project-relation-manager__item__group__item">
                  <FormControl
                    label="Título"
                    value={item.title}
                    onChange={value => this._handleChange('title', value, index)}
                    style={{ marginBottom: 10 }}
                  />
                </div>
                <div className="project-relation-manager__item__group__item project-relation-manager__item__group__item--small-last">
                  <FormControl
                    label="Coluna"
                    type="radioButtons"
                    options={columnOptions}
                    value={item.column}
                    onChange={value => this._handleChange('column', value, index)}
                    style={{ marginBottom: 10 }}
                  />
                </div>
              </div>
              <FormControl
                label="Texto"
                type="texteditor"
                value={item.content}
                onChange={value => this._handleChange('content', value, index)}
              />
            </div>
            <div className="project-relation-manager__item__control project-relation-manager__item__control--actions">
              <DragHandle />
              <span
                className="project-relation-manager__item__remove"
                onClick={() => this._removeItem(index)}
              >
                <Icon name="trash" />
              </span>
            </div>
          </div>
        </SortableItem>
      )
    })
  }

  _addItem = () => {
    const model = {
      ...this.state.model,
      items: [
        ...this.state.model.items,
        {
          title: '',
          content: '',
          column: 0,
          sort: this.state.model.items.length,
        }
      ]
    }
    this.setState({ model }, () => {
      this.props.onChange([...model.items])
    })
  }

  _removeItem = (index) => {
    const model = {
      ...this.state.model,
      items: this.state.model.items.filter((_, i) => {
        return +i !== +index
      })
    }
    this.setState({ model }, () => {
      this.props.onChange([...model.items])
    })
  }

  _handleChange = (field, value, index) => {
    const model = {
      ...this.state.model,
      items: this.state.model.items.map((item, i) => {
        if (+i === +index) {
          item[field] = value
        }
        return item
      })
    }
    this.setState({ model }, () => {
      this.props.onChange([...model.items])
    })
  }

  _getClasses = () => {
    let classes = ['project-relation-manager']
    if (this.props.errors.length > 0) {
      classes.push('project-relation-manager--error')
    }
    return classes.join(' ')
  }

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state.model
    const newItems = arrayMove(items, oldIndex, newIndex)
      .map((item, index) => ({
        ...item,
        sort: index
      }))
    const model = {
      ...this.state.model,
      items: newItems
    }
    this.setState({ model }, () => {
      this.props.onChange([...model.items])
    })
  }
}

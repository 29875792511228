import fecha from 'fecha'

export default {
  formatDatetime(date, formatParse = 'YYYY-MM-DD HH:mm:ss', formatOutput = 'HH:mm:ss DD/MM/YYYY') {
    if (date instanceof Date) {
      return fecha.format(date, formatOutput)
    }
    const fechaDate = fecha.parse(date, formatParse)
    return fecha.format(fechaDate, formatOutput)
  },
  formatDate(date, formatParse = 'YYYY-MM-DD', formatOutput = 'DD/MM/YYYY') {
    if (date instanceof Date) {
      return fecha.format(date, formatOutput)
    }
    const fechaDate = fecha.parse(date, formatParse)
    return fecha.format(fechaDate, formatOutput)
  },
  parseDatetime(value, formatParse = 'YYYY-MM-DD HH:mm:ss') {
    if (value instanceof Date) {
      return value
    }
    return fecha.parse(value, formatParse)
  },
  parseDate(value, formatParse = 'YYYY-MM-DD') {
    if (value instanceof Date) {
      return value
    }
    return fecha.parse(value, formatParse)
  }
}
